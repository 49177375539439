import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';


const IndexPage = ({ data={} }) => {

  const { modules, title, description, id } = mapPageData(data.page)


  const modulesToRender = useModules(modules)

  return (
  <>
  <SEO title={title} description={description}/>
  <Layout 
    className="static-page">
      {/* Content goes here */}
      { modulesToRender.map( ({Module, id}) =>  <Module key={id} /> )}
  </Layout>
  </>
)}

export default IndexPage


export const IndexPageQuery = graphql`
  query ExamplePageQuery {
  page: contentfulPage(pageId: {eq: "example-components"}) {
      ...PageFragment
    }
  }
`
